<template>
  <!-- loading START -->
  <transition name="dialog-fade" mode="out-in">
    <div class="loading loading-default" v-if="loadingVisible">
      <div class="loading-default-container">

        <!-- spinner START -->
        <div class="loader-container">
          <div class="loader loader-one"></div>
          <div class="loader loader-two"></div>
        </div>
        <!-- spinner END -->

        <h4 v-html="headline"></h4>
        <div
          :class="[
            'excerpt',
            { 'languages' : loadingType === 'languages' }
          ]"
          v-html="excerpt"
        ></div>

        <!-- cart:actions:checkout:abort START -->
        <div
          class="abort"
          v-on:click="abort()"
          v-if="showRestoreCart"
        >
          <span v-html="$t('terminal.cart.checkout.abort.headline')"></span>
        </div>
        <!-- cart:actions:checkout:abort END -->

        <!-- cart:actions:checkout:abort START -->
        <div
          class="abort"
          v-if="showCloseAprove"
        >
          <div
            class="btn-inverted close"
            v-on:click="closeApprove()"
            v-on:touchstart="touchStart($event)"
            v-on:touchend="touchEnd($event);"
            v-on:touchcancel="touchEnd($event)"
            v-html="$t('terminal.index.cart.purchase.abort')"
          ></div>
        </div>
        <!-- cart:actions:checkout:abort END -->
      </div>
    </div>
  </transition>
  <!-- loading END -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'loading-default',
  props: {
    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    showRestoreCart: {
      type: Boolean,
    },

    showCloseAprove: {
      type: Boolean,
    },

    abort: {
      type: Function,
    },

    closeApprove: {
      type: Function,
    },
    loadingType: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    async resetStoreCart() {
      // reset store cart
      await this.clearCart();

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      await this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');
          }
        });

      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
